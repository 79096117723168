.App-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* For width 400px and larger: */
@media only screen and (max-width: 500px) {
  .Header-image {
    height: 100px;
  }
}

/* For width 400px and larger: */
@media only screen and (min-width: 501px) {
  .Header-image {
    height: 200px;
  }
}

#custom-input {
  border: 1px solid #666;
  border-radius: 5px;
  height: 48px;
  padding: 0 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font: inherit;
  font-size: 18px;
  font-weight: 300;
  position: relative;
  outline: none;
  background-color: #fff;
  width: 100%;
  color: #333;
  user-select: text;
  appearance: none;
  display: flex;
  align-items: center;
  caret-color: #086adb;
  cursor: inherit;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
